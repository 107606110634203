<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12">
            <list-table-data
              :table-columns="tableColumns"
              :table-data="items"
              :redirect-edit="redirectEdit"
              :redirect-show="redirectShow"
              :origen="catalogoName"
              :show-icon-new="true"
              :tipo-catalogo="catalogoType"
            />
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard, BRow, BCol } from 'bootstrap-vue'
import ListTableData from '@/components/ListTableData.vue'
import { getlistado } from '@/utils/catalogos'

export default {
  components: {
    ListTableData,
    BCard,
    BRow,
    BCol,
  },
  data() {
    return {
      catalogoName: 'Tamaños Accesorios',
      catalogoType: 32,
      redirectEdit: 'catalogos-edit-tamanos-accesorios',
      redirectShow: 'catalogos-edit-tamanos-accesorios',
      items: [],
      tableColumns: [
        {
          label: 'Nombre',
          field: 'nombre',
        },
        {
          label: 'Tipo',
          field: 'tipo',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Medida',
          field: 'valor',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Unidad de Medida',
          field: 'unidad',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Estado',
          field: 'active',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Acciones',
          field: 'acciones',
          thClass: 'vgt-center-align',
          tdClass: 'text-center',
        },
      ],
    }
  },
  async beforeMount() {
    try {
      const user = JSON.parse(localStorage.getItem('userData'))

      this.items = await getlistado(
        this.catalogoType,
        this.catalogoName,
        true,
        user,
      )
      this.items.sort((a, b) => {
        // Primero ordena por tipo, poniendo 'POSTE' antes que 'BRAZO'
        if (a.tipo === 'POSTE' && b.tipo === 'BRAZO') {
          return -1 // a viene antes que b
        } if (a.tipo === 'BRAZO' && b.tipo === 'POSTE') {
          return 1 // b viene antes que a
        }
        // Si ambos tienen el mismo tipo, ordena por id
        return a.id - b.id
      })
      if (this.items === null) this.items = []
    } catch (err) {
      console.error(`Error en cargar ${this.catalogoName}`, err)
      this.items = []
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
